export const XCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <circle cx="24" cy="24" r="24" fill="#FF3600" />
      <g clipPath="url(#clip0_9747_2)">
        <path
          d="M35.6668 14.6833L33.3168 12.3333L24.0002 21.65L14.6835 12.3333L12.3335 14.6833L21.6502 24L12.3335 33.3166L14.6835 35.6666L24.0002 26.35L33.3168 35.6666L35.6668 33.3166L26.3502 24L35.6668 14.6833Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9747_2">
          <rect width="40" height="40" fill="white" transform={'translate(4 4)'} />
        </clipPath>
      </defs>
    </svg>
  )
}

export const CheckedCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
        d="M24 0C10.752 0 0 10.752 0 24C0 37.248 10.752 48 24 48C37.248 48 48 37.248 48 24C48 10.752 37.248 0 24 0ZM19.2 36L7.2 24L10.584 20.616L19.2 29.208L37.416 10.992L40.8 14.4L19.2 36Z"
        fill="#5DCB44"
      />
    </svg>
  )
}

export const DeliveryIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="44" height="44" rx="22" fill="#5DCB44" fillOpacity="0.12" />
      <g clipPath="url(#clip0_9464_467)">
        <path
          d="M29.5837 16.5833C29.5837 15.3917 28.6087 14.4167 27.417 14.4167H24.167V16.5833H27.417V19.4542L23.647 24.1667H19.8337V18.75H15.5003C13.1062 18.75 11.167 20.6892 11.167 23.0833V26.3333H13.3337C13.3337 28.1317 14.7853 29.5833 16.5837 29.5833C18.382 29.5833 19.8337 28.1317 19.8337 26.3333H24.687L29.5837 20.2125V16.5833ZM16.5837 27.4167C15.9878 27.4167 15.5003 26.9292 15.5003 26.3333H17.667C17.667 26.9292 17.1795 27.4167 16.5837 27.4167Z"
          fill="#5DCB44"
        />
        <path d="M19.8337 15.5H14.417V17.6667H19.8337V15.5Z" fill="#5DCB44" />
        <path
          d="M29.5835 23.0833C27.7852 23.0833 26.3335 24.535 26.3335 26.3333C26.3335 28.1317 27.7852 29.5833 29.5835 29.5833C31.3818 29.5833 32.8335 28.1317 32.8335 26.3333C32.8335 24.535 31.3818 23.0833 29.5835 23.0833ZM29.5835 27.4167C28.9877 27.4167 28.5002 26.9292 28.5002 26.3333C28.5002 25.7375 28.9877 25.25 29.5835 25.25C30.1793 25.25 30.6668 25.7375 30.6668 26.3333C30.6668 26.9292 30.1793 27.4167 29.5835 27.4167Z"
          fill="#5DCB44"
        />
      </g>
      <defs>
        <clipPath id="clip0_9464_467">
          <rect width="26" height="26" fill="white" transform="translate(9 9)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const ArrowUpSvg = ({ fill }: { fill?: string }) => {
  const fillColor = fill ? fill : '#45C65A'
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={'ml-3 -mr-1 h-5 w-5'}
    >
      <path
        d="M9.00005 0.236197L17.8 9.0362L15.5667 11.2695L9.00005 4.66953L2.43338 11.2362L0.200048 9.00286L9.00005 0.236197Z"
        fill={fillColor}
      />
    </svg>
  )
}

export const ArrowDownSvg = ({ fill }: { fill?: string }) => {
  const fillColor = fill ? fill : '#45C65A'
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={'ml-3 -mr-1 h-5 w-5'}
    >
      <path
        d="M8.99995 11.2677L0.199951 2.46771L2.43328 0.234375L8.99995 6.83438L15.5666 0.267708L17.8 2.50104L8.99995 11.2677Z"
        fill={fillColor}
      />
    </svg>
  )
}
