import { atom } from 'recoil'
import { v1 } from 'uuid'

import { localStorageEffect } from '@lib/localstorage'

const answerResultsGroupKey = 'answerResultsGroup'
const answerResultsGroupAtom = atom({
  key: `${answerResultsGroupKey}Raw${v1()}`,
  default: { normal: {}, delivery: {} },
  effects: [localStorageEffect(answerResultsGroupKey)],
})

export default answerResultsGroupAtom
