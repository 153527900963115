export const get = (key: string, initialValue?: any) => {
  try {
    if (window?.localStorage?.getItem(key) == null) {
      return initialValue
    }
    return window.localStorage.getItem(key)
  } catch (error) {
    return initialValue
  }
}

export const set = (key: string, value: string) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, value)
  }
}

export const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = get(key)
    if (savedValue != null && savedValue !== 'undefined') {
      setSelf(JSON.parse(savedValue))
    }
    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset ? window && window.localStorage.removeItem(key) : set(key, JSON.stringify(newValue))
    })
  }

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { get, set }
