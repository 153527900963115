import { useEventLogging } from '@packages/jslib'
import Image from 'next/image'

import KakaoShareByCustomTemplate from '@components/Button/KakaoShareButton/KakaoShareByCustomTemplate'

export const UTM_PARAMS =
  'utm_source=externalsharing&utm_medium=viral&utm_campaign=header-share-button'

function getCurrentUrl() {
  const originalUrl = document.location.href
  return originalUrl + (originalUrl.match(/\?/) ? '&' : '?') + UTM_PARAMS
}

const KakaoShareButtonContainer = ({ shareTitle = null, shareUrl = null }) => {
  const { trackButtonClick } = useEventLogging()

  const handleClickShare = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()

    const title = shareTitle || document.title
    const url = shareUrl || getCurrentUrl()
    trackButtonClick({
      name: 'Share',
      properties: {
        title,
        url,
      },
    })

    await KakaoShareByCustomTemplate()
  }

  return (
    <div className={'text-center'} onClick={handleClickShare}>
      <Image alt="카카오톡 공유하기" src="/images/kakao_share_icon.svg" width={86} height={40} />
    </div>
  )
}

export default KakaoShareButtonContainer
