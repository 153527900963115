import { useRouter } from 'next/router'
import { useEventLogging } from '@packages/jslib'

import { DeliveryIcon } from '@components/svgs'

const HomeListDeliveryBanner = () => {
  const router = useRouter()
  const { trackButtonClick } = useEventLogging()
  const bannerClickCallback = () => {
    trackButtonClick({ name: 'Cbt Home List Delivery Button' })
    router.push('/delivery')
  }
  return (
    <div className={'flex w-full px-3 py-5 border rounded-xl shadow'} onClick={bannerClickCallback}>
      <DeliveryIcon />
      <div className={'mx-auto'}>
        <p className={'font-bold'}>{'케어파트너 기출문제 배달'}</p>
        <p className={'text-sm'}>{'기출문제를 배달해 드립니다.'}</p>
      </div>
      <p className={'my-auto ml-auto text-2xl text-[#999999]'}>{'>'}</p>
    </div>
  )
}

export default HomeListDeliveryBanner
