import { atom } from 'recoil'
import { v1 } from 'uuid'

import { localStorageEffect } from '@lib/localstorage'
import { CommentaryVideo } from '@components/QuestionGroupBoxes'

export type questionIdAndNumber = { id: number; number: number }
export type questionGroupType = {
  id: number | null
  title: string
  type: string
  questions: questionIdAndNumber[]
  commentaryVideo?: CommentaryVideo
}

const questionGroupKey = 'questionGroup'
const defaultQuestionGroup = { id: null, title: '', type: 'public', questions: [] }
const questionGroupAtom = atom<questionGroupType>({
  key: `${questionGroupKey}Raw${v1()}`,
  default: defaultQuestionGroup,
  effects: [localStorageEffect(questionGroupKey)],
})

export { questionGroupAtom as default, defaultQuestionGroup }
