import React from 'react'
import Link from 'next/link'

type LinkType = {
  url: string
  new?: boolean
}

export type ButtonProps = {
  label?: string
  link?: LinkType
  onClick?: () => void
  children?: string | JSX.Element | JSX.Element[]
}

export type BaseButtonProps = ButtonProps & {
  className?: string
}

export const BaseButtonStyleClassName =
  'w-full flex justify-center align-center py-2.5 px-4 rounded-lg font-bold text-base'

const Button = ({ label, link, onClick, children, className }: BaseButtonProps) => {
  const buttonComponent = (
    <button className={className} onClick={onClick ? onClick : () => undefined}>
      {label ?? children}
    </button>
  )
  if (link) {
    return (
      <Link href={link.url} target={link.new ? '_blank' : '_self'}>
        {buttonComponent}
      </Link>
    )
  }
  return buttonComponent
}

Button.base = (props: BaseButtonProps) => {
  const className = `${BaseButtonStyleClassName} ${props.className ? props.className : ''}`
  return <Button {...props} className={className}></Button>
}

Button.primary = (props: BaseButtonProps) => {
  const className = `text-white bg-bosalpim ${props.className ? props.className : ''}`
  return <Button.base {...props} className={className}></Button.base>
}

Button.secondary = (props: BaseButtonProps) => {
  const className = `text-primary-text bg-white border border-bosalpim ${
    props.className ? props.className : ''
  }`
  return <Button.base {...props} className={className}></Button.base>
}

export default Button
