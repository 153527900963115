/* eslint-disable no-var */
import * as Sentry from '@sentry/nextjs'

const templateIdMap = {
  home: 90012,
}

declare global {
  var Kakao: any
}

const KakaoShareByCustomTemplate = async () => {
  const templateId: number = templateIdMap.home
  const templateArgs: Record<string, any> = {}

  try {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.NEXT_PUBLIC_KAKAO_DEVELOPER_KEY)
    }
  } catch (e) {
    Sentry.captureMessage(e as string)
  }

  window.Kakao.Share.sendCustom({
    templateId,
    templateArgs,
  })
}

export default KakaoShareByCustomTemplate
