import React from 'react'

import Button from '@components/Button'
import { CommentaryVideo } from '@components/QuestionGroupBoxes'

type QuestionGroupBoxProps = {
  questionGroupTitle: string
  takeExamButtonHandler: () => void
  takeVideoButtonHandler: (commentaryVideo: CommentaryVideo) => void
  isPublic?: boolean
  isLoggedIn: boolean
  commentaryVideo?: CommentaryVideo
}

const QuestionGroupBox: React.FC<QuestionGroupBoxProps> = ({
  questionGroupTitle,
  takeExamButtonHandler,
  takeVideoButtonHandler,
  isPublic,
  isLoggedIn,
  commentaryVideo,
}) => {
  return (
    <div
      className={
        'mt-4 w-full sm:w-[660px] min-h-[156px] bg-appback py-8 border rounded-xl border-eaeaea'
      }
    >
      <h2 className={'text-allFourGrey text-center text-lg sm:text-2xl font-bold'}>
        {questionGroupTitle}
      </h2>
      {!isLoggedIn && (
        <div className={'text-allFourGrey text-center text-base sm:text-xl mt-4'}>
          {isPublic
            ? '비회원의 경우 맛보기 5문제 제공'
            : '회원가입 시 모의고사 전체 문제 무료 제공'}
        </div>
      )}
      <div className={'w-[260px] m-auto mt-6'}>
        <Button.primary onClick={takeExamButtonHandler}>
          {isLoggedIn
            ? '시험 응시하기'
            : isPublic
            ? '비회원 모의고사 체험하기'
            : '회원가입하고 무료 모의고사 보기'}
        </Button.primary>
      </div>
      {isLoggedIn && commentaryVideo && commentaryVideo.url && (
        <div className={'w-[260px] mt-2 m-auto'}>
          <Button.secondary onClick={() => takeVideoButtonHandler(commentaryVideo)}>
            모의고사 해설 보기
          </Button.secondary>
        </div>
      )}
    </div>
  )
}

export default QuestionGroupBox
