import { atom } from 'recoil'
import { v1 } from 'uuid'

import { localStorageEffect } from '@lib/localstorage'

const currentDeliveryGroupIdKey = `currentDeliveryGroupId`
const currentDeliveryGroupIdAtom = atom<number>({
  key: `${currentDeliveryGroupIdKey}Raw${v1()}`,
  default: 1,
  effects: [localStorageEffect(currentDeliveryGroupIdKey)],
})

export default currentDeliveryGroupIdAtom
