import React, { useEffect, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import Image from 'next/image'
import Link from 'next/link'

import { classNames, getCookie } from '@lib/simpleFn'
import KakaoShareButton from '@components/Button/KakaoShareButton'

type HomeNavbarMenu = {
  name: string
  path: string
  target?: '_blank'
}

const carepartnerDomain = process.env.NEXT_PUBLIC_CAREPARTNER_URL ?? 'https://carepartner.kr'

const CarepartnerHeader: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    setIsAuthenticated(!!getCookie('app_at'))
  }, [])

  const diffMenus: HomeNavbarMenu[] = isAuthenticated
    ? [
        {
          name: '관심일자리',
          path: carepartnerDomain + '/saved_jobs',
        },
        {
          name: '내 정보',
          path: carepartnerDomain + '/me',
        },
      ]
    : [
        {
          name: '기관회원 구인서비스',
          path: 'https://business.carepartner.kr',
          target: '_blank',
        },
      ]

  const webMenus: HomeNavbarMenu[] = [
    {
      name: '홈으로',
      path: carepartnerDomain,
    },
    {
      name: '요양일자리',
      path: carepartnerDomain + '/jobs',
    },
    {
      name: '지도보기',
      path: carepartnerDomain + '/map',
    },
    {
      name: '요양매거진',
      path: carepartnerDomain + '/magazines',
    },
    {
      name: 'CBT모의고사',
      path: carepartnerDomain + '/cbt_landing',
    },
    ...diffMenus,
  ]

  return (
    <Disclosure as="nav" className="bg-white shadow">
      <div
        className={
          'fixed w-full top-0 bg-white flex sm:hidden font-bold pl-5 py-[12px] text-lg pr-4 z-10'
        }
      >
        <div className={'content-start my-auto'}>CBT 실전 모의고사</div>
        <div className={'content-end ml-auto'}>
          <KakaoShareButton />
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 sm:block hidden">
        <div className="flex h-16 justify-between">
          <div className="flex flex-shrink-0 items-center">
            <Link href={'/'}>
              <Image
                src="/images/logo.png"
                alt="케어파트너"
                width={140}
                height={35}
                className={'mt-1'}
              />
            </Link>
          </div>
          <div className="hidden  sm:flex lg:space-x-8 ">
            {webMenus.map((menu) => (
              <Link
                key={menu.path}
                href={menu.path}
                className={classNames(
                  menu.name === 'CBT모의고사' ? 'border-b-2 border-bosalpim' : '',
                  'inline-flex items-center px-1 pt-1 sm:text-sm lg:text-lg font-medium text-gray-900 hover:text-gray-600 whitespace-pre-wrap'
                )}
              >
                {menu.name}
              </Link>
            ))}
          </div>
          <div className="flex items-center">
            {!isAuthenticated && (
              <div className="flex-shrink-0">
                <Link
                  href={
                    carepartnerDomain +
                    `/users/login/?prevPath=${process.env.NEXT_PUBLIC_CBT_URL || '/'}`
                  }
                >
                  <button
                    type="button"
                    className="relative inline-flex items-center rounded-md border border-transparent bg-bosalpim px-4 py-2 text-lg font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-bosalpim focus:ring-offset-2"
                  >
                    <span>로그인</span>
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </Disclosure>
  )
}

export default CarepartnerHeader
