import { atom } from 'recoil'
import { v1 } from 'uuid'

const viewResultModeKey = 'viewResultMode'
const viewResultModeAtom = atom({
  key: `${viewResultModeKey}Raw/${v1()}`,
  default: false,
})

export default viewResultModeAtom
