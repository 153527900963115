import React from 'react'
import Image from 'next/image'

import { getImageUrl } from '@lib/simpleFn'

export const RequestActivationModal = () => {
  return (
    <div className="relative">
      <div className="m-auto w-[240px] h-[450px] overflow-hidden">
        <Image
          className="w-full"
          width="220"
          height="430"
          alt="정보등록을 완료해 주세요."
          src={getImageUrl('request-activation-modal.webp')}
          unoptimized
        />
      </div>
      <div className="absolute w-full h-[170px] bottom-0 bg-white py-6">
        <h1 className="text-2xl">모의고사 무료 이용을 위해 정보등록을 완료해 주세요.</h1>
        <p className="text-base font-medium tracking-tighter mt-3">
          1분이면 등록 가능합니다. 정보등록 하시고 CBT 모의고사와 해설영상을 이용해 보세요.
        </p>
      </div>
    </div>
  )
}
