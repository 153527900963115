import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useEventLogging } from '@packages/jslib'

import useExamInform from '@hooks/useExamInform'
import QuestionGroupBox from '@components/QuestionGroupBoxes/QuestionGroupBox'
import { getAllQuestionGroup } from '@lib/query/queryFunction'
import { AlertModal } from '@components/Modal'
import { RequestActivationModal } from '@components/QuestionGroupBoxes/RequestActivationModal'
import useAuth from '@hooks/useAuth'

export type CommentaryVideo = {
  id: number
  title?: string
  url: string
  thumbnail?: string
  questionGroupId: number
}

export type QuestionGroup = {
  id: number
  title: string
  type: string
  commentaryVideo?: CommentaryVideo
  year: number
}

const QuestionGroupBoxes: React.FC = () => {
  const { setCurrentGroupId, setCurrentQuestionGroupByGroupId } = useExamInform()
  const { trackShow, trackButtonClick } = useEventLogging()
  const [questionGroups, setQuestionGroups] = useState<QuestionGroup[]>([])
  const [showActiveAlertModal, setShowActiveAlertModal] = useState(false)
  const [showVideoAlertModal, setShowVideoAlertModal] = useState(false)
  const [videoPath, setVideoPath] = useState<string>('')
  const router = useRouter()
  const { user, isAuthenticated } = useAuth()
  const userStatus = useMemo(() => {
    return user?.status
  }, [user])

  useEffect(() => {
    const gettingQuestionGroups = async () => {
      const qgs: QuestionGroup[] = await getAllQuestionGroup()
      if (!qgs || qgs.length === 0) return
      const sortedQg = qgs.sort((lQg, rQg) => {
        if (lQg.type === 'public' && rQg.type !== 'public') {
          return -1
        } else if (rQg.type === 'public' && lQg.type !== 'public') {
          return 1
        }
        return lQg.id - rQg.id
      })
      setQuestionGroups(sortedQg)
    }

    gettingQuestionGroups()
  }, [])

  const goToLoginOrActivate = () => {
    const carepartnerUrl = process.env.NEXT_PUBLIC_CAREPARTNER_URL ?? 'https://carepartner.kr'
    const prevPath = `${window.location.protocol}//${window.location.host}`
    window.location.href = `${carepartnerUrl}/login?prevPath=${prevPath}`
  }

  const isActive = useCallback(
    (questionGroup: QuestionGroup) => {
      return (isAuthenticated && userStatus === 'active') || questionGroup.type === 'public'
    },
    [isAuthenticated, userStatus]
  )

  const showActiveAlertModalHandler = (state: boolean) => {
    setShowActiveAlertModal(state)
    state &&
      trackShow({
        name: 'Draft SignUp Modal',
      })
  }

  const renderQuestionGroup = (questionGroup: QuestionGroup) => {
    const takeExamButtonHandler = async () => {
      setCurrentGroupId(questionGroup.id)
      trackButtonClick({
        name: 'Take Exam',
        properties: {
          isLoggedIn: isAuthenticated,
          questionGroup: questionGroup.id,
        },
      })

      if (isActive(questionGroup)) {
        await setCurrentQuestionGroupByGroupId(questionGroup.id)
        router.push('/preview')
      } else {
        showActiveAlertModalHandler(true)
      }
    }

    const takeVideoButtonHandler = async (commentaryVideo: CommentaryVideo) => {
      trackButtonClick({
        name: 'Exam List Solution',
      })
      if (isActive(questionGroup)) {
        setVideoPath(commentaryVideo.url)
        setShowVideoAlertModal(true)
      } else {
        showActiveAlertModalHandler(true)
      }
    }

    const props = {
      questionGroupTitle: questionGroup.title,
      takeExamButtonHandler,
      takeVideoButtonHandler,
      isPublic: questionGroup?.type == 'public',
      isLoggedIn: isAuthenticated,
      commentaryVideo: questionGroup.commentaryVideo,
    }
    if (questionGroup.type === 'member' || (questionGroup.type === 'public' && !isAuthenticated)) {
      return <QuestionGroupBox key={questionGroup.id} {...props} />
    }
  }

  return (
    <div className={'flex flex-col'}>
      <AlertModal
        show={showActiveAlertModal}
        setShow={setShowActiveAlertModal}
        data={{
          xButton: true,
          body: <RequestActivationModal />,
          submitAction: async () => {
            trackButtonClick({
              name: 'Draft SignUp Modal-Button',
            })
            goToLoginOrActivate()
          },
        }}
      />
      <AlertModal
        show={showVideoAlertModal}
        setShow={setShowVideoAlertModal}
        data={{
          body: (
            <div className="mt-2 mb-5 text-xl font-normal">
              모의고사 해설 영상입니다.
              <br />
              해설영상은 모의고사를 먼저
              <br />
              풀어보시고 보시는 것을 권장합니다.
            </div>
          ),
          submitLabel: '모의고사 해설 보기',
          submitAction: () => {
            trackButtonClick({
              name: 'Exam List Mosal Solution',
            })
            window.open(videoPath, '_blank')
          },
          cancelButton: true,
          xButton: true,
        }}
      />
      {questionGroups.filter((questionGroup) => questionGroup.year === 2024).length > 0 && (
        <>
          <h2 className="block mt-8 text-[22px] font-bold">2024년 교육과정</h2>
          {questionGroups
            .filter((questionGroup) => questionGroup.year === 2024)
            .map(renderQuestionGroup)}
        </>
      )}
      <h2 className="block mt-8 text-[22px] font-bold">2024년 이전 교육과정</h2>
      {questionGroups
        .filter((questionGroup) => questionGroup.year !== 2024)
        .map(renderQuestionGroup)}
    </div>
  )
}

export default QuestionGroupBoxes
