import { atom } from 'recoil'
import { v1 } from 'uuid'

import { localStorageEffect } from '@lib/localstorage'

const currentGroupIdKey = `currentGroupId`
const currentGroupIdAtom = atom<number>({
  key: `${currentGroupIdKey}Raw${v1()}`,
  default: 1,
  effects: [localStorageEffect(currentGroupIdKey)],
})

export default currentGroupIdAtom
