import { atom } from 'recoil'
import { v1 } from 'uuid'

import { localStorageEffect } from '@lib/localstorage'

const userChoicesAndWrongsGroupKey = `userChoicesAndWrongsGroup`
const userChoicesAndWrongsGroupAtom = atom({
  key: `${userChoicesAndWrongsGroupKey}Raw${v1()}`,
  default: { normal: {}, delivery: {} },
  effects: [localStorageEffect(userChoicesAndWrongsGroupKey)],
})

export default userChoicesAndWrongsGroupAtom
